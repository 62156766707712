import {
  createEvent,
  createEffect,
  sample,
  createStore,
  split,
  combine,
} from 'effector';
import { persist } from 'effector-storage/local';
import {
  stores as orderStores,
  actions as orderActions,
} from 'src/effector/order';
import {
  actions as searchWidgetActions,
  store as searchWidgetStore,
} from 'src/effector/searchWidget';
import { actions as checkoutActions } from 'src/effector/checkout';
import { actions as addonsActions } from 'src/effector/addons';
import { actions as paymentActions } from 'src/effector/checkout/payment';
import { actions as deliveryActions } from 'src/effector/checkout/delivery';
import { actions as cartActions } from 'src/effector/cart';
import { actions as productsActions } from 'src/effector/products';
import { actions as featuredProductsActions } from 'src/effector/products/filters/featured';
import {
  stores as protectionPlanStores,
  actions as protectionPlanActions,
} from 'src/effector/checkout/protectionPlans';
import {
  stores as paymentPeriodStores,
  actions as paymentPeriodActions,
} from 'src/effector/paymentPeriod';
import * as api from 'src/logic/api';
import get from 'lodash/get';
import sumBy from 'lodash/sumBy';
import keyBy from 'lodash/keyBy';
import filter from 'lodash/filter';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import uniqWith from 'lodash/uniqWith';
import mapValues from 'lodash/mapValues';
import isEqual from 'lodash/isEqual';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import has from 'lodash/has';

const pushToOirtrik = data => {
  window._oirtrk = window._oirtrk || [];
  window._oirtrk.push(['event', data]);
};
const pushToDataLayer = (data, opts = {}) => {
  window.dataLayer = window.dataLayer || { push: console.log };
  if (opts.ecommerce) {
    window.dataLayer.push({ ecommerce: null });
  }
  window.dataLayer.push(data);
};

const fetchCheckoutViewedAnalyticsFx = createEffect().use(
  api.fetchCheckoutViewedAnalytics,
);

const fetchCartViewedAnalyticsFx = createEffect().use(
  api.fetchCartViewedAnalytics,
);

const pickReferrer = () => {
  const search = new URLSearchParams(window.location.search);
  const result = ['utm_medium', 'utm_source', 'utm_campaign']
    .map(k => search.get(k))
    .filter(Boolean)
    .join('-');
  if (result) {
    return result;
  }
  if (window.document.referrer) {
    return window.document.referrer;
  }
  return 'direct load';
};

const getReferrer = () => {
  try {
    const referrerData = JSON.parse(localStorage.getItem('referrerData'));

    if (referrerData && referrerData.expirationTime > new Date().getTime()) {
      return referrerData.referrer;
    }
    const expirationTime = new Date().getTime() + 1000 * 60 * 60 * 2;
    const referrer = pickReferrer();
    localStorage.setItem(
      'referrerData',
      JSON.stringify({ referrer, expirationTime }),
    );
    return referrer;
  } catch {
    return window.document.referrer;
  }
};

const setData = createEvent();
const updateData = createEvent();
const $analyticsData = createStore({
  products: [],
  searchData: {},
  appliedFilters: {},
})
  .on(updateData, (s, d) => ({
    ...s,
    ...d,
    products: uniqWith(s.products.concat(d.products || []), isEqual),
  }))
  .on(setData, (s, d) => ({ ...s, ...d }));

const log = createEvent();
const logCustom = createEvent();
const logCommerce = createEvent();
const logPaypair = createEvent();
const logDefault = createEvent();

const logCustomFx = createEffect().use(d => {
  const event = get(d, 'eventName');
  const data = get(d, 'data', {});
  const obj = {
    event,
    commerce: {
      ...data,
    },
  };

  pushToDataLayer(omitBy(obj, isEmpty), { ecommerce: true });
  pushToOirtrik(omitBy(obj, isEmpty));
});

const logPaypairFx = createEffect().use(d => {
  const event = get(d, 'eventName');
  const obj = { event };
  pushToDataLayer(obj);
  pushToOirtrik(obj);
});

const logDefaultFx = createEffect().use(d => {
  const event = get(d, 'eventName');
  const data = get(d, 'data', {});
  const searchData = get(d, 'searchData', {});
  const appliedFilters = get(d, 'appliedFilters', {});
  const userProps = get(d, 'userProps', {});

  const objToPush = {
    event,
    user_data: userProps,
    referrer: getReferrer(),
    ...searchData,
    ...appliedFilters,
    ...data,
  };
  pushToDataLayer(omitBy(objToPush, isEmpty));
  pushToOirtrik(omitBy(objToPush, isEmpty));
});

const getProductsByParams = (products, params) => {
  const key = ['variant_id', 'master_id'].find(k =>
    params.some(p => has(p, k)),
  );
  const paramsById = isEmpty(params)
    ? keyBy(products, 'master_id')
    : keyBy(params, key);

  return uniqBy(
    products
      .filter(product => paramsById[product.master_id])
      .map(product => ({
        ...product,
        quantity: +get(
          paramsById,
          [product.master_id, 'quantity'],
          product.quantity,
        ),
      })),
    'master_id',
  );
};

const logCommerceFx = createEffect().use(d => {
  const event = get(d, 'eventName');
  const productsParams = get(d, 'data.productsParams', []);
  const allStoredProducts = get(d, 'products', []);
  const products = get(d, 'data.products', allStoredProducts);
  const userProps = get(d, 'userProps', {});
  const searchData = get(d, 'searchData', {});
  const customData = get(d, 'data.customData', {});

  const specificData = [
    {
      key: 'item_list_id',
      value: get(searchData, 'search_category'),
    },
    {
      key: 'item_list_name',
      value: get(searchData, 'search_method'),
    },
  ]
    .filter(({ value }) => Boolean(value))
    .reduce((res, { key, value }) => ({ ...res, [key]: value }), {});

  const checkoutData = [
    {
      key: 'checkout_step',
      value: get(d, 'data.checkout_step'),
    },
  ]
    .filter(({ value }) => Boolean(value))
    .reduce((res, { key, value }) => ({ ...res, [key]: value }), {});

  const items = getProductsByParams(products, productsParams)
    .map(p => omit(p, 'master_id'))
    .map(p => ({ ...p, total_price: p.quantity * p.price, ...specificData }));

  const totalItemsPrice = sumBy(items, 'total_price');

  const objToPush = {
    event,
    ecommerce: {
      currency: 'USD',
      items,
      user_data: userProps,
      referrer: getReferrer(),
      value: totalItemsPrice,
      ...specificData,
      ...checkoutData,
      ...customData,
    },
  };
  pushToDataLayer(omitBy(objToPush, isEmpty), { ecommerce: true });
  pushToOirtrik(omitBy(objToPush, isEmpty));
});

const clearGtmUserProperties = createEvent();
const $gtmUserProperties = createStore({})
  .reset(clearGtmUserProperties)
  .on(orderStores.$order, (_, o) => {
    try {
      const ship_address = get(o, 'ship_address', {});
      return {
        user_id: o.number,
        email: o.email,
        first_name: ship_address.firstname,
        last_name: ship_address.lastname,
        phone: ship_address.phone,
        address: ship_address.address1,
        city: ship_address.city,
        state: ship_address.state_name,
        zip: ship_address.zipcode,
      };
    } catch (e) {
      return {};
    }
  });

const $formattedUserProperties = $gtmUserProperties.map(p => {
  if (isNil(p?.first_name)) {
    return {};
  }
  const props = mapValues(p, v => v?.toLowerCase());
  return {
    email: props.email,
    phone_number: `+1${props.phone?.split('-').join('')}`,
    user_id: p.user_id,
    address: {
      first_name: props.first_name,
      last_name: props.last_name,
      street: props.address,
      city: props.city,
      state: props.state,
      zip: props.zip,
      country: 'US',
    },
  };
});

sample({
  clock: logDefault,
  source: { userProps: $formattedUserProperties, store: $analyticsData },
  fn: ({ userProps, store }, params) => ({ ...store, ...params, userProps }),
  target: logDefaultFx,
});

sample({
  clock: logCommerce,
  source: { userProps: $formattedUserProperties, store: $analyticsData },
  fn: ({ userProps, store }, params) => ({ ...store, ...params, userProps }),
  target: logCommerceFx,
});

sample({
  clock: logPaypair,
  target: logPaypairFx,
});

sample({
  clock: logCustom,
  target: logCustomFx,
});

split({
  source: log,
  match: e => e.eventType,
  cases: {
    commerce: logCommerce,
    paypair: logPaypair,
    custom: logCustom,
    __: logDefault,
  },
});

const events = {
  homepageClickShoptiresAndwheels: log.prepend(() => ({
    eventName: 'CTA Clicked',
    eventType: 'Custom',
    data: {
      type: 'button',
      name: 'homepage_click_shoptiresandwheels',
    },
    dirtyData: ['destination', 'path'],
  })),

  homepageClickBrandFindTires: log.prepend(() => ({
    eventName: 'CTA Clicked',
    eventType: 'Custom',
    data: {
      type: 'button',
      name: 'homepage_click_brand_findtires',
    },
    dirtyData: ['destination', 'path'],
  })),

  homepageClickTireDealsSeeAll: log.prepend(({ destination }) => ({
    eventName: 'CTA Clicked',
    eventType: 'Custom',
    data: {
      type: 'link',
      name: 'homepage_click_tiredeals_seeall',
      destination,
    },
    dirtyData: ['path'],
  })),

  homepageFooterClicks: log.prepend(({ destination }) => ({
    eventName: 'CTA Clicked',
    eventType: 'Custom',
    data: {
      type: 'link',
      destination,
      name: 'homepage_footer_clicks',
    },
    dirtyData: ['path'],
  })),

  homepageClickTireDealsRight: log.prepend(() => ({
    eventName: 'CTA Clicked',
    eventType: 'Custom',
    data: {
      type: 'button',
      name: 'homepage_click_tiredeals_right',
    },
    dirtyData: ['destination', 'path'],
  })),

  homepageClickTireDealsLeft: log.prepend(() => ({
    eventName: 'CTA Clicked',
    eventType: 'Custom',
    data: {
      type: 'button',
      name: 'homepage_click_tiredeals_left',
    },
    dirtyData: ['destination', 'path'],
  })),

  cartClickHaveAPromoCode: log.prepend(() => ({
    eventName: 'CTA Clicked',
    eventType: 'Custom',
    data: {
      type: 'button',
      name: 'cart_click_haveapromocode',
    },
    dirtyData: ['destination', 'path'],
  })),

  cartClickContinueShopping: log.prepend(({ destination }) => ({
    eventName: 'CTA Clicked',
    eventType: 'Custom',
    data: {
      type: 'link',
      destination,
      name: 'cart_click_continueshopping',
    },
    dirtyData: ['path'],
  })),

  cartClickBeginCheckout: log.prepend(({ destination }) => ({
    eventName: 'CTA Clicked',
    eventType: 'Custom',
    data: {
      type: 'link',
      destination,
      name: 'cart_click_begincheckout',
    },
    dirtyData: ['path'],
  })),

  checkoutPaymentClickCompletePurchase: log.prepend(({ destination }) => ({
    eventName: 'CTA Clicked',
    eventType: 'Custom',
    data: {
      type: 'link',
      destination,
      name: 'checkout_payment_click_completepurchase',
    },
  })),

  checkoutPaymentClickPaymentPlansPayPairContinueToPayPair: log.prepend(() => ({
    eventName: 'CTA Clicked',
    eventType: 'Custom',
    data: {
      type: 'button',
      name: 'checkout_payment_click_paymentplans_paypair_continuetopaypair',
    },
  })),

  contactUsClickCancellationRequest: log.prepend(({ destination }) => ({
    eventName: 'CTA Clicked',
    eventType: 'Custom',
    data: {
      type: 'link',
      destination,
      name: 'contactus_click_cancellationrequest',
    },
    dirtyData: ['path'],
  })),

  contactUsClickEmailAddress: log.prepend(() => ({
    eventName: 'CTA Clicked',
    eventType: 'Custom',
    data: {
      type: 'button',
      name: 'contactus_click_emailaddress',
    },
    dirtyData: ['destination', 'path'],
  })),

  contactUsClickPhoneNumber: log.prepend(() => ({
    eventName: 'CTA Clicked',
    eventType: 'Custom',
    data: {
      type: 'button',
      name: 'contactus_click_phonenumber',
    },
    dirtyData: ['destination', 'path'],
  })),

  accessoriesProductPageClickAddToCart: log.prepend(data => ({
    eventName: 'CTA Clicked',
    eventType: 'Custom',
    data: {
      type: 'button',
      name: 'accessories_productpage_click_addtocart',
      ...data,
    },
    dirtyData: ['destination', 'path'],
  })),

  accessoriesSearchResultsClickAddToCart: log.prepend(data => ({
    eventName: 'CTA Clicked',
    eventType: 'Custom',
    data: {
      type: 'button',
      name: 'accessories_searchresults_click_addtocart',
      ...data,
    },
    dirtyData: ['destination', 'path'],
  })),

  tiresProductPageClickAvailableSizesViewDetails: log.prepend(
    ({ destination }) => ({
      eventName: 'CTA Clicked',
      eventType: 'Custom',
      data: {
        type: 'link',
        destination,
        name: 'tires_productpage_click_availablesizes_viewdetails',
      },
      dirtyData: ['path'],
    }),
  ),

  filtersApplied: log.prepend(data => ({
    eventName: 'Filter Applied',
    eventType: 'Custom',
    data,
  })),

  siteSearched: log.prepend(data => ({
    eventName: 'Site Searched',
    eventType: 'Custom',
    data,
  })),

  screenView: log.prepend(data => ({
    eventName: 'Page Viewed',
    eventType: 'PageViewed',
    data,
  })),

  productItemViewed: log.prepend(data => ({
    eventName: 'view_item',
    eventType: 'commerce',
    data,
  })),

  productListViewed: log.prepend(data => ({
    eventName: 'view_item_list',
    eventType: 'commerce',
    data,
  })),

  selectItem: log.prepend(data => ({
    eventName: 'select_item',
    eventType: 'commerce',
    data: {
      productsParams: data,
    },
  })),

  addToCart: log.prepend(data => ({
    eventName: 'add_to_cart',
    eventType: 'commerce',
    data,
  })),

  cartViewed: log.prepend(data => ({
    eventName: 'cart_viewed',
    eventType: 'commerce',
    data,
  })),

  removeFromCart: log.prepend(data => ({
    eventName: 'remove_from_cart',
    eventType: 'commerce',
    data,
  })),

  beginCheckout: log.prepend(data => ({
    eventName: 'begin_checkout',
    eventType: 'commerce',
    data,
  })),

  checkoutStepViewed: log.prepend(data => ({
    eventName: 'checkout_progress',
    eventType: 'commerce',
    data,
  })),

  сheckoutStepCompleted: log.prepend(data => ({
    eventName: 'Checkout',
    eventType: 'commerce',
    data,
  })),

  checkoutPaymentPurchase: log.prepend(data => ({
    eventName: 'purchase',
    eventType: 'commerce',
    data,
  })),
  paypairEventFired: log.prepend(data => ({
    eventName: [data.eventName, get(data, 'payload.lender', '')]
      .filter(Boolean)
      .join(' '),
    eventType: 'paypair',
    data,
  })),
  startSearchBy: log.prepend(data => ({
    eventName: `start search by ${data['search method']} - ${data['search category']}`,
    eventType: 'custom',
    data,
  })),
  confirmAndContinue: log.prepend(data => ({
    eventName: `confirm and continue ${data['search method']} - ${data['search category']}`,
    eventType: 'custom',
    data,
  })),
  confirmFitment: log.prepend(data => ({
    eventName: 'confirm fitment',
    eventType: 'custom',
    data,
  })),
  confirmAndViewResult: log.prepend(data => ({
    eventName: `confirm and view results ${data['search method']} - ${data['search category']}`,
    eventType: 'custom',
    data,
  })),
  startNewProductSearch: log.prepend(() => ({
    eventName: 'start new product search',
    eventType: 'custom',
  })),
  changeSize: log.prepend(() => ({
    eventName: 'change size',
    eventType: 'custom',
  })),
  changeVehicle: log.prepend(() => ({
    eventName: 'change vehicle',
    eventType: 'custom',
  })),
  addAllstateProtectionPlanAtc: log.prepend(data => ({
    eventName: 'allstate add protection plan atc',
    eventType: 'custom',
    data: {
      'allstate plan length': `${data[0].plan.year} year plan`,
    },
  })),
  allstateNoThanksAtc: log.prepend(() => ({
    eventName: 'allstate no thanks atc',
    eventType: 'custom',
  })),

  addExtendProtectionPlanAtc: log.prepend(data => ({
    eventName: 'extend add protection plan atc',
    eventType: 'custom',
    data: {
      'extend plan length': `${data.plan.term_length} year plan`,
    },
  })),
  extendNoThanksAtc: log.prepend(() => ({
    eventName: 'extend no thanks atc',
    eventType: 'custom',
  })),
  paymentPeriodChanged: log.prepend(data => ({
    eventName: 'show payment as',
    eventType: 'custom',
    data: {
      'show payment as': data.key,
    },
  })),
  checkoutStepChanged: log.prepend(data => ({
    eventName: data.eventName,
    eventType: 'custom',
  })),
  deliverToYou: log.prepend(() => ({
    eventName: 'deliver to you',
    eventType: 'custom',
  })),
  addInstallation: log.prepend(() => ({
    eventName: 'add installation',
    eventType: 'custom',
  })),
  addProtectionPlan: log.prepend(data => ({
    eventName: 'add protection plan',
    eventType: 'custom',
    data,
  })),
  addTpms: log.prepend(data => ({
    eventName: 'add tpms',
    eventType: 'custom',
    data,
  })),
  addInflator: log.prepend(data => ({
    eventName: 'add inflator to cart',
    eventType: 'custom',
    data,
  })),
  selectPaypair: log.prepend(() => ({
    eventName: 'continue paypair',
    eventType: 'custom',
  })),
  selectAffirm: log.prepend(() => ({
    eventName: 'continue affirm',
    eventType: 'custom',
  })),
  selectStripe: log.prepend(() => ({
    eventName: 'pay debit credit',
    eventType: 'custom',
  })),
  selectPaypal: log.prepend(() => ({
    eventName: 'pay paypal',
    eventType: 'custom',
  })),
  selectCashapp: log.prepend(() => ({
    eventName: 'pay cashapp',
    eventType: 'custom',
  })),
  openFilterSortDrawer: log.prepend(() => ({
    eventName: 'filter & sort',
    eventType: 'custom',
  })),
  selectFindTires: log.prepend(() => ({
    eventName: 'select find tires',
    eventType: 'custom',
  })),
  matchingTireSizes: log.prepend(() => ({
    eventName: 'matching tire sizes',
    eventType: 'custom',
  })),

  updateLineItem: log.prepend(data => ({
    eventName: 'edit quantity',
    eventType: 'custom',
    data: {
      quantity: data.quantity,
    },
  })),
  clickPromoCode: log.prepend(() => ({
    eventName: 'promo code',
    eventType: 'custom',
  })),

  featreudFilterApplied: log.prepend(data => ({
    eventName: data.eventName,
    eventType: 'custom',
  })),
  navigateTo: log.prepend(data => ({
    eventName: data.eventName,
    eventType: 'custom',
  })),
  clickProductRecommendation: log.prepend(data => ({
    eventName: data.eventName,
    eventType: 'custom',
  })),
};

sample({
  clock: orderActions.createLineItem,
  filter: params => params.find(({ type }) => ['tire', 'wheel'].includes(type)),
  fn: params => ({
    productsParams: params,
  }),
  target: events.addToCart,
});

sample({
  clock: orderActions.createLineItem,
  source: {
    paymentPeriod: paymentPeriodStores.$selectedPaymentPeriod,
  },
  filter: (_, params) => params.find(({ type }) => ['inflater'].includes(type)),
  fn: ({ paymentPeriod }) => ({
    'show payment as': paymentPeriod.key,
  }),
  target: events.addInflator,
});

sample({
  clock: orderActions.destroyLineItem,
  source: {
    lineItems: orderStores.$lineItems,
  },
  filter: (_, { type }) => ['tire', 'wheel'].includes(type),
  fn: ({ lineItems }, { id }) => ({
    productsParams: filter(lineItems, { id }),
  }),
  target: events.removeFromCart,
});

sample({
  clock: checkoutActions.updatedCheckout,
  target: fetchCheckoutViewedAnalyticsFx,
});

sample({
  clock: fetchCheckoutViewedAnalyticsFx.doneData,
  fn: data => ({
    products: data.products,
  }),
  target: setData,
});

sample({
  clock: fetchCheckoutViewedAnalyticsFx.doneData,
  fn: data => ({ checkout_step: data.checkout_data.checkout_step }),
  target: events.checkoutStepViewed,
});

sample({
  clock: paymentActions.purchaseWithStripe,
  target: events.checkoutPaymentClickCompletePurchase.prepend(() => ({
    destination: '/checkout/complete',
  })),
});

sample({
  clock: cartActions.openCartDrawer,
  target: fetchCartViewedAnalyticsFx,
});

sample({
  clock: fetchCartViewedAnalyticsFx.doneData,
  target: updateData,
});

sample({
  clock: fetchCartViewedAnalyticsFx.doneData,
  target: events.cartViewed,
});

sample({
  clock: paymentActions.onPaypairEventFired,
  target: events.paypairEventFired,
});

sample({
  clock: searchWidgetActions.start,
  source: searchWidgetStore,
  fn: ({ $shopBy, $productType }) => ({
    'search method': $shopBy,
    'search category': $productType,
  }),
  target: events.startSearchBy,
});

sample({
  clock: ['tires', 'wheels', 'both'].map(
    type => searchWidgetActions.vehicle[type].verify.confirm,
  ),
  source: searchWidgetStore,
  fn: ({ $shopBy, $productType, picked: { data, tireSizeToRender } }) => ({
    'search method': $shopBy,
    'search category': $productType,
    brand: data.makes.current.value,
    year: data.years.current.value,
    model: data.models.current.value,
    trim: data.trims.current.value,
    'tire size': tireSizeToRender,
  }),
  target: events.confirmAndContinue,
});

sample({
  clock: searchWidgetActions.size.tires.wheelDiameters.confirm,
  source: searchWidgetStore,
  fn: ({ $shopBy, $productType, picked: { data } }) => ({
    'search method': $shopBy,
    'search category': $productType,
    'tire width': data.tireWidths.current.value,
    'aspect ratio': data.aspectRatios.current.value,
    'wheel diameter': data.wheelDiameters.current.value,
  }),
  target: events.confirmAndContinue,
});

sample({
  clock: searchWidgetActions.size.wheels.offsets.confirm,
  source: searchWidgetStore,
  fn: ({ $shopBy, $productType, picked: { data } }) => ({
    'search method': $shopBy,
    'search category': $productType,
    'wheel diameter': data.diameters.current.value,
    'wheel width': data.widths.current.value,
    lugs: data.lugs.current.value,
    'bolt circle': data.boltPatterns.current.value,
    'wheel offset': data.offsets.options
      .filter(o => data.offsets.current[o.id])
      .map(o => o.name)
      .join(','),
  }),
  target: events.confirmAndContinue,
});

sample({
  clock: searchWidgetActions.size.tires.fitment.confirm,
  source: searchWidgetStore,
  fn: ({ $shopBy, $productType, picked: { data } }) => ({
    'search method': $shopBy,
    'search category': $productType,
    'staggered fitment': { staggered: 'yes', 'non-staggered': 'no' }[
      data.fitment.current.id
    ],
  }),
  target: events.confirmFitment,
});

sample({
  clock: [
    searchWidgetActions.size.wheels.zip.confirm,
    searchWidgetActions.vehicle.tires.zip.confirm,
  ],
  source: searchWidgetStore,
  fn: ({ $shopBy, $productType, picked: { data } }) => ({
    'search method': $shopBy,
    'search category': $productType,
    'zip code': data.zip.current.value,
  }),
  target: events.confirmAndViewResult,
});

sample({
  clock: searchWidgetActions.vehicle.wheels.zip.confirm,
  source: searchWidgetStore,
  fn: ({ $shopBy, $productType, picked: { data } }) => ({
    'search method': $shopBy,
    'search category': $productType,
    'rim size': `${data.rim.current.value}`,
    'zip code': data.zip.current.value,
  }),
  target: events.confirmAndViewResult,
});

sample({
  clock: searchWidgetActions.size.tires.zip.confirm,
  source: searchWidgetStore,
  fn: ({ $shopBy, $productType, picked: { data } }) => ({
    'search method': $shopBy,
    'search category': $productType,
    'tire width': data.tireWidths.current.value,
    'tire aspect': data.aspectRatios.current.value,
    'tire diameter': data.wheelDiameters.current.value,
    'zip code': data.zip.current.value,
  }),
  target: events.confirmAndViewResult,
});

sample({
  clock: searchWidgetActions.startNewProductSearch,
  target: events.startNewProductSearch,
});

sample({
  clock: searchWidgetActions.changeSize,
  target: events.changeSize,
});
sample({
  clock: searchWidgetActions.changeVehicle,
  target: events.changeVehicle,
});

sample({
  clock: protectionPlanActions.addAllstatePlansFromFlayout,
  target: events.addAllstateProtectionPlanAtc,
});

sample({
  clock: protectionPlanActions.closeAllstateFlayout,
  target: events.allstateNoThanksAtc,
});

sample({
  clock: protectionPlanActions.addExtendPlansFromFlayout,
  target: events.addExtendProtectionPlanAtc,
});

sample({
  clock: protectionPlanActions.closeExtendFlayout,
  target: events.extendNoThanksAtc,
});

sample({
  clock: protectionPlanActions.selectProtectionPlan,
  source: {
    protectionPlans: protectionPlanStores.$protectionPlans,
    paymentPeriod: paymentPeriodStores.$selectedPaymentPeriod,
  },
  filter: (_, { isSelect }) => isSelect,
  fn: ({ protectionPlans, paymentPeriod }, { lineItemId }) => {
    const plan = protectionPlans.find(item => item.line_item_id === lineItemId);
    const {
      selectedYearPlan: { year },
    } = plan;
    return {
      'show payment as': paymentPeriod.key,
      ...{
        tire: {
          'allstate plan length': `${year} year plan`,
        },
        wheel: {
          'extend plan length': `${year} year plan`,
        },
      }[plan.line_item_info.product_type],
    };
  },
  target: events.addProtectionPlan,
});

sample({
  clock: paymentPeriodActions.setPaymentPeriod,
  target: events.paymentPeriodChanged,
});

sample({
  clock: checkoutActions.changeStep,
  fn: step => ({
    eventName: {
      delivery: 'continue to delivery',
      address: 'continue to contact info',
      addons: 'continue to protection plans',
      accessories: 'continue to add-ons',
      payment: 'continue to payment',
    }[step],
  }),
  target: events.checkoutStepChanged,
});

sample({
  clock: deliveryActions.updateFedexFx.doneData,
  target: events.deliverToYou,
});
sample({
  clock: deliveryActions.updatePreferredLocationFx.doneData,
  target: events.addInstallation,
});
sample({
  clock: addonsActions.addToCartTpms,
  source: {
    paymentPeriod: paymentPeriodStores.$selectedPaymentPeriod,
  },
  fn: ({ paymentPeriod }) => ({
    'show payment as': paymentPeriod.key,
  }),
  target: events.addTpms,
});

sample({
  clock: featuredProductsActions.setValue,
  fn: value => ({
    eventName:
      {
        deals: 'Deals',
        bestWarranty: 'Great Warranty',
        cheap: 'Best Value',
        allWeather: 'All Weather',
        recommended: 'Recommended',
      }[value] || value,
  }),
  target: events.clickProductRecommendation,
});
sample({
  clock: featuredProductsActions.clearValue,
  target: events.clickProductRecommendation.prepend(() => ({
    eventName: 'All',
  })),
});

sample({
  clock: paymentActions.initPaypair,
  target: events.selectPaypair,
});
sample({
  clock: paymentActions.initAffirm,
  target: events.selectAffirm,
});
sample({
  clock: paymentActions.selectStripe,
  target: events.selectStripe,
});
sample({
  clock: paymentActions.selectCashapp,
  target: events.selectCashapp,
});
sample({
  clock: paymentActions.selectPaypal,
  target: events.selectPaypal,
});
sample({
  clock: productsActions.openFilterSortDrawer,
  target: events.openFilterSortDrawer,
});
sample({
  clock: orderActions.updateLineItem,
  target: events.updateLineItem,
});
sample({
  clock: cartActions.togglePromoCodeForm,
  target: events.clickPromoCode,
});

persist({ store: $gtmUserProperties, key: 'gtm-user-properties' });

export default events;

const stores = {
  $analyticsData,
};

export const actions = {
  ...events,
  setData,
  clearGtmUserProperties,
};

export const store = combine(stores);
